<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <NavBar tittle="label.lbl_stockInquiryByClient" />
      <!-- 検索項目 -->
      <v-form ref="form" lazy-validation>
        <v-container fluid>
          <!-- １行目スタート -->
          <v-row class="search-row">
            <!-- 取引先 -->
            <div
              class="search-autocomplete search-autocomplete-supplierList first-search-item"
              style="float: left"
            >
              <v-autocomplete
                dense
                id="get-supplier"
                v-model="suppliersSelected"
                :items="supplierList"
                :label="$t('label.lbl_supplier')"
                @change="changeSuppliersSelected"
                :rules="[rules.supplierRequired]"
                persistent-hint
                :hint="setSrhSupplier()"
                :error-messages="alertSupplierMessage"
              >
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size"> {{ data.item.text }} </span></template
                >
              </v-autocomplete>
            </div>
            <span class="require" style="float: left">*</span>

            <!-- 空白 -->
            <span class="item-spacer" style="float: left">&nbsp;</span>

            <!-- 営業所 -->
            <div class="search-autocomplete" style="float: left">
              <v-autocomplete
                dense
                v-model="officeSelected"
                :items="officeList"
                :label="$t('label.lbl_salesOffice')"
                class="txt-single"
                persistent-hint
                ><template slot="item" slot-scope="data">
                  <span class="auto-txt-size"> {{ data.item.text }} </span></template
                >
              </v-autocomplete>
            </div>
            <!-- 空白 -->
            <span class="item-spacer" style="float: left">&nbsp;</span>
            <!-- 検索区分(ユーザー/メーカー)-->
            <div style="float: left">
              <v-btn-toggle
                mandatory
                v-model="userMakerSected"
                color="blue darken-2"
                id="btn-toggle-btn-padding"
              >
                <v-btn
                  class="paid-btn"
                  v-for="item in userMakerList"
                  :key="item.value"
                  :value="item.value"
                  small
                >
                  {{ item.text }}</v-btn
                >
              </v-btn-toggle>
            </div>
            <!-- 空白 -->
            <span class="item-spacer" style="float: left">&nbsp;</span>
            <!-- 商品コード -->
            <div class="search-autocomplete" style="float: left">
              <v-autocomplete
                dense
                v-model="nameSelected"
                :items="nameList"
                @change="(event) => changeProductNm(event)"
                :hint="checkProductCd()"
                :search-input.sync="search"
                :label="itemCdLabel"
                persistent-hint
              >
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size"> {{ data.item.text }} </span></template
                >
              </v-autocomplete>
            </div>

            <!-- 空白 -->
            <span class="item-spacer" style="float: left">&nbsp;</span>

            <!-- 入荷ステータス-->
            <div class="search-autocomplete" style="float: left">
              <v-autocomplete
                dense
                v-model="qualityDivSelected"
                :items="qualityDivList"
                :label="$t('label.lbl_arrivalStatus')"
                class="txt-single"
                persistent-hint
                ><template slot="item" slot-scope="data">
                  <span class="auto-txt-size"> {{ data.item.text }} </span></template
                >
              </v-autocomplete>
            </div>
            <!-- 空白 -->
            <span class="item-spacer" style="float: left">&nbsp;</span>
            <span class="item-spacer" style="float: left">&nbsp;</span>

            <!-- 在庫有無 -->
            <div class="search-autocomplete" style="float: left">
              <v-autocomplete
                dense
                v-model="inStockSelected"
                :items="inStockList"
                :label="$t('label.lbl_inStock')"
                class="txt-single"
                persistent-hint
                ><template slot="item" slot-scope="data">
                  <span class="auto-txt-size"> {{ data.item.text }} </span></template
                >
              </v-autocomplete>
            </div>

            <!-- 空白 -->
            <span class="item-spacer" style="float: left">&nbsp;</span>

            <!-- 品番前方一致3桁 -->
            <div class="search-textbox" style="float: left">
              <v-text-field
                dense
                outlined
                v-model="itemSelected"
                :label="matchItemCdLabel"
                persistent-hint
                clear-icon="mdi-close-circle"
                clearable
                maxlength="50"
              >
              </v-text-field>
            </div>
          </v-row>
          <v-row>
            <div class="search-row-exeBtn">
              <!--ボタン領域-->
              <div class="btn-search-area" style="float: left">
                <!-- 検索ボタン -->
                <v-btn
                  color="primary"
                  id="get-search"
                  class="api-btn"
                  @click="apigetStock('get-search')"
                  >{{ $t("btn.btn_search") }}</v-btn
                >
              </div>
              <!--画面クリアボタン-->
              <v-btn class="other-btn" @click="clearScreen()">{{ $t("btn.btn_clear") }}</v-btn>
              <!--CSV出力ボタン領域-->
              <div class="btn-area">
                <!--CSV出力ボタン-->
                <v-btn class="other-btn-nosize btn-size" @click="outPutClick()">{{
                  $t("btn.btn_outputcsv")
                }}</v-btn>
              </div>
            </div>
          </v-row>

          <v-row>
            <div class="display-search-result-row">
              <!--検索結果表示-->
              <div class="text-label" style="float: left">
                <span>総件数：{{ totalCount }}件</span>
              </div>
              <!--表示件数切替部分-->
              <div style="float: right">
                <!--表示件数-->
                <div class="text-label" style="clear: both; float: left">
                  {{ $t("label.lbl_itemsPerPage") }}
                </div>
                <!-- 空白 -->
                <span class="item-spacer" style="float: left">&nbsp;</span>
                <!--表示件数切替ボタン-->
                <div style="float: left">
                  <v-btn-toggle
                    v-model="itemsPerPageBtn"
                    color="blue darken-2"
                    class="toggle black--text"
                    right
                  >
                    <v-btn class="pasing-btn" value="100" @click="u_100_on()" small>
                      {{ 100 }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="250" @click="u_100_clear(250)" small>
                      {{ 250 }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="500" @click="u_100_clear(500)" small>
                      {{ 500 }}
                    </v-btn>
                  </v-btn-toggle>
                </div>
              </div>
            </div>
          </v-row>
          <!-- 2行目ここまで -->
        </v-container>
      </v-form>
      <!-- データテーブル -->
      <v-container fluid>
        <v-data-table
          id="listData"
          fixed-header
          :headers="headerItems"
          :items="inputTableList"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          disable-filtering
          :hide-default-footer="true"
          group-by="groupByNo"
          height="600px"
        >
          <!-- 明細No -->

          <template v-slot:[`group`]="{ group, items }">
            <tr>
              <!-- <td>
                <div v-for="(item, key) in items" :key="key">
                  {{ item.itemCd }}
                </div>
              </td> -->
              <td style="vertical-align: top">
                <!-- <v-btn small @click="openUpdateDelete(item)" text> -->
                <v-btn small @click="toDetail(items[0])" text>
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </td>

              <td style="vertical-align: top">
                <div v-if="10 < items[0].itemName.length">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div class="text-truncate" style="max-width: 280px" v-bind="attrs" v-on="on">
                        {{ items[0].itemCd }}
                        <br />
                        {{ items[0].itemName }}
                      </div>
                    </template>
                    <div>
                      {{ items[0].itemCd }}
                      <br />
                      {{ items[0].itemName }}
                    </div>
                  </v-tooltip>
                </div>
                <div v-else>
                  {{ items[0].itemCd }}
                  <br />
                  {{ items[0].itemName }}
                </div>
              </td>
              <td style="vertical-align: top">
                {{ items[0].accessoryCd }}
              </td>

              <td style="vertical-align: top">
                <p class="font-size">{{ items[0].total }}</p>
              </td>

              <td style="padding: 0px; vertical-align: top">
                <template v-for="(groupingData, key) in items">
                  <div :key="`c-1-${key}`">
                    <div v-for="(item, index) in groupingData.officeSumList" :key="`c-2-${index}`">
                      <p
                        v-if="index == 0 && groupingData.officeSumList.length > 1 && key == 0"
                        align="left"
                        class="font-size-char"
                      >
                        {{ groupingData.arrivalStatus }}
                      </p>
                      <p
                        v-else-if="index == 0 && groupingData.officeSumList.length > 1 && key != 0"
                        class="border_top_char"
                        align="left"
                      >
                        {{ groupingData.arrivalStatus }}
                      </p>
                      <p
                        v-else-if="index == 0 && groupingData.officeSumList.length == 1 && key == 0"
                        align="left"
                        class="font-size-char"
                      >
                        {{ groupingData.arrivalStatus }}
                      </p>
                      <p
                        v-else-if="index == 0 && groupingData.officeSumList.length == 1 && key != 0"
                        class="border_top_char"
                        align="left"
                      >
                        {{ groupingData.arrivalStatus }}
                      </p>
                      <p v-else style="height: 39px">&nbsp;</p>
                    </div>
                  </div>
                </template>
              </td>

              <td style="padding: 0px; vertical-align: top">
                <template v-for="(groupingData, key) in items">
                  <div :key="`c-1-${key}`">
                    <div v-for="(item, index) in groupingData.officeSumList" :key="`c-2-${index}`">
                      <p
                        v-if="index == 0 && groupingData.officeSumList.length > 1 && key == 0"
                        class="font-size"
                      >
                        {{ groupingData.totalSum }}
                      </p>
                      <p
                        v-else-if="index == 0 && groupingData.officeSumList.length > 1 && key != 0"
                        class="border_top_num"
                        align="center"
                      >
                        {{ groupingData.totalSum }}
                      </p>
                      <p
                        v-else-if="index == 0 && groupingData.officeSumList.length == 1 && key == 0"
                        class="font-size"
                      >
                        {{ groupingData.totalSum }}
                      </p>
                      <p
                        v-else-if="index == 0 && groupingData.officeSumList.length == 1 && key != 0"
                        class="border_top_num"
                        align="center"
                      >
                        {{ groupingData.totalSum }}
                      </p>
                      <p v-else style="height: 39px">&nbsp;</p>
                    </div>
                  </div>
                  <!-- <div
                    v-if="items.length > 1 && key != 0 && items[0].officeSumList.length > 1"
                    border="solid"
                    align="right"
                    :key="`b-1-${key}`"
                  >
                    {{ groupingData.totalSum }}
                  </div>

                  <div v-else align="right" :key="`b-4-${key}`">
                    {{ groupingData.totalSum }}
                  </div> -->
                </template>
              </td>

              <td style="padding: 0px">
                <template v-for="(groupingData, grIndex) in items">
                  <div :key="`c-1-${grIndex}`">
                    <div v-for="(item, index) in groupingData.officeSumList" :key="`c-2-${index}`">
                      <p
                        v-if="index == 0 && grIndex != 0 && groupingData.officeSumList.length > 1"
                        class="border_top_saleoffice"
                      >
                        {{ item.salesOffice }}
                      </p>
                      <p
                        v-else-if="
                          index == 0 && grIndex != 0 && groupingData.officeSumList.length == 1
                        "
                        class="border_top_saleoffice"
                      >
                        {{ item.salesOffice }}
                      </p>
                      <p v-else class="not_border_top_saleoffice" style="vertical-align: top">
                        {{ item.salesOffice }}
                      </p>
                    </div>
                  </div>
                </template>
              </td>
              <td style="padding: 0px">
                <template v-for="(groupingData, grIndex) in items">
                  <div :key="`d-1-${grIndex}`">
                    <div v-for="(item, index) in groupingData.officeSumList" :key="`d-2-${index}`">
                      <p
                        v-if="index == 0 && grIndex != 0 && groupingData.officeSumList.length > 1"
                        align="right"
                        class="border_top"
                      >
                        {{ item.quantity }}
                      </p>
                      <p
                        v-else-if="
                          index == 0 && grIndex != 0 && groupingData.officeSumList.length == 1
                        "
                        align="right"
                        class="border_top"
                      >
                        {{ item.quantity }}
                      </p>
                      <p v-else align="right" style="height: 39px">
                        {{ item.quantity }}
                      </p>
                    </div>
                  </div>
                </template>
              </td>
            </tr>
          </template>
        </v-data-table>
        <div class="text-center pt-2">
          <v-pagination
            v-model="page"
            :length="Math.ceil(groupCountList.length / itemsPerPage)"
            :total-visible="7"
          ></v-pagination>
        </div>
      </v-container>
      <!-- ナビゲーション -->
      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :firstPageFlag="infoDialog.firstPageFlag"
      />
      <ConfirmDialog
        :isShow.sync="confirmDialog.isOpen"
        :message="confirmDialog.message"
        :screenFlag="confirmDialog.screenFlag"
        :okAction="confirmDialog.okAction"
        :redMessage="confirmDialog.redMessage"
        :changeFlag="confirmDialog.changeFlag"
      />
    </v-sheet>
  </div>
</template>

<script>
import Loading from "@/components/loading";
import sideMenu from "@/components/Menu";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import { i18n } from "../../lang/lang.js";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import NavBar from "../../components/NavBar.vue";
import { getParameter } from "../../assets/scripts/js/GetParameter";
import SimpleDialog from "@/components/SimpleDialog";
import { commonFunction } from "../../assets/scripts/js/CommonFunction";
import ConfirmDialog from "@/components/ConfirmDialog";
import commonMixin from "@/mixins/CommonMixin";
import commonRules from "@/mixins/CommonRules";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import { v4 as uuidv4 } from "uuid";

export default {
  name: appConfig.SCREEN_ID.P_CLT_001,
  components: {
    Loading,
    sideMenu,
    NavBar,
    SimpleDialog,
    ConfirmDialog,
  },
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
    entity: { type: Object, default: null },
  },
  mixins: [commonMixin, commonRules],
  data: () => ({
    // ローディング画面表示フラグ
    loadingCounter: 0,
    u_100: false,
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      firstPageFlag: true,
    },
    // 確認メッセージ
    confirmDialog: {
      isOpen: false,
      message: "",
      redMessage: "",
      okAction: () => {},
    },
    rules: {
      supplierRequired: (value) => !!value || i18n.tc("check.chk_inputSupplier"),
      dataFormatRules: (value) => !!value || i18n.tc("check.chk_outputFormatDiv"),
      outPutPatternRules: (value) => !!value || i18n.tc("check.chk_outputPattern"),
      inputRequired: (value) => !!value || i18n.tc("check.chk_input"),
      inputNumberRange: (value) => value >= 4 || i18n.tc("check.chk_inputNumberRange_more_3"),
    },
    // 検索時点の日時
    year: "",
    today: "",
    nowTime: "",
    search: "",
    // ページング
    itemsPerPageList: [100, 250, 500],
    page: 1,
    pageCount: 0,
    totalCount: 0,
    itemsPerPage: 100,
    itemsPerPageBtn: "100",
    // 選択された取引先
    suppliersSelected: [],
    // 取引先SID
    clientSid: "",
    // 選択された営業所
    officeSelected: "",
    // 営業所リスト
    officeList: [],
    // 選択されたユーザー品番
    userProductCd: "",
    // ユーザー品番リスト
    userProductCdList: [],
    // 取引先アラート
    alertSupplierMessage: "",
    // ユーザー/メーカートグル
    userMakerSected: "02",
    userMakerList: [],
    // 選択された品質区分
    qualityDivSelected: "",
    // 選択された品番/品名
    nameSelected: "",
    // 選択された在庫有無
    inStockSelected: "",
    // メニュー
    openMenu: null,
    // ソート
    sortItem: "",
    sortOptions: {},
    // 初期データ
    defaultData: [],
    // ヘッダ
    headerItems: [
      // 詳細
      {
        text: i18n.tc("label.lbl_Detail"),
        value: "detail",
        width: "5%",
        align: "left",
        sortable: false,
      },
      // 品番/品名
      {
        text: i18n.tc("label.lbl_productCnCd"),
        value: "itemCd",
        width: "14%",
        align: "left",
        sortable: true,
      },
      // 付帯品番
      {
        text: i18n.tc("label.lbl_productAccessoryCd"),
        value: "accessoryCd",
        width: "14%",
        align: "left",
        sortable: true,
      },
      // 合計
      {
        text: i18n.tc("label.lbl_totalSum"),
        value: "total",
        width: "10%",
        align: "right",
        sortable: false,
      },
      // 品質区分
      {
        text: i18n.tc("label.lbl_arrivalStatus"),
        value: "arrivalStatus",
        width: "23%",
        align: "left",
        sortable: false,
      },
      // 合計
      {
        text: i18n.tc("label.lbl_totalSum"),
        value: "totalSum",
        width: "12%",
        align: "right",
        class: "total-sum-header",
        sortable: false,
      },
      // 営業所
      {
        text: i18n.tc("label.lbl_salesOffice"),
        value: "salesOffice",
        width: "10%",
        align: "center",
        sortable: false,
        class: "office-header",
      },
      // 数量
      {
        text: i18n.tc("label.lbl_quantity"),
        value: "quantity",
        width: "12%",
        align: "right",
        class: "qty-header",
        sortable: false,
      },
    ],
    //検索条件
    searchParam: {
      searchSuppliersSelected: "", // 取引先
      searchOfficeSelected: "", // 営業所
      searchProductCd: "", // 品番
      searchUserProductCd: "", // ユーザー品番
      searchQualityDiv: "", // 品質区分
      searchInstockDiv: "", // 在庫有無
      searchItemSelected: "", // 品番前方一致キーワード
      searchClientCd: "", // 取引先CD
    },
    inputList: [],
    // 検索結果
    stockList: [],
    // 営業所ごとの数量リスト
    officeSumList: [],
    // 取引先プルダウン
    supplierList: [],
    // 入荷ステータスプルダウン
    qualityDivList: [],
    clearFlg: false,
    // 品番前方一致
    itemSelected: "",
    // 在庫有無プルダウン
    inStockList: [],
    groupCountList: [],
    inputTableList: [],
    itemCdLabel: "",
    itemCdLabelList: [],
    matchItemCdLabel: "",
    matchItemCdLabelList: [],
    suppliersSelectedName: "",
    officeSid: [],
    lot: "",
    qualityDiv: "",
    itemName: "",
    itemStandardSid: "",
    outPutList: [],
    userItemBaseCd: "",
    // 品番品名プルダウン
    nameList: [],
    // 取引先コード
    clientCd: "",
    productHint: "",
  }),
  methods: {
    // テーブルの余白調整
    u_10_check() {
      if (this.itemsPerPage < 11) {
        this.u_10 = true;
      }
    },
    u_100_on() {
      this.u_100 = true;
      this.itemsPerPage = 100;
      this.page = 1;
      this.showList();
    },
    u_100_clear(val) {
      this.u_100 = false;
      this.itemsPerPage = val;
      this.page = 1;
      this.showList();
    },
    // 初期化
    init() {
      // 初期データ保持
      this.defaultData = Object.assign({}, this.$data);
      if (this.$route.params.searchParam && this.clearFlg != true) {
        this.searchParam = this.$route.params.searchParam; // 検索条件
        this.suppliersSelected = this.$route.params.suppliersSelected; // 取引先
        this.officeSelected = this.$route.params.searchParam.searchOfficeSelected; // 営業所
        this.nameSelected = this.$route.params.searchParam.searchProductCd; // 品番
        this.userProductCd = this.$route.params.searchParam.searchUserProductCd; // ユーザー品番
        this.qualityDivSelected = this.$route.params.searchParam.searchQualityDiv; // 品質区分
        this.inStockSelected = this.$route.params.searchParam.searchInstockDiv; // 在庫有無区分
        this.itemSelected = this.searchParam.searchItemSelected; // 品番前方一致キーワード
        this.clientCd = this.searchParam.searchClientCd; // 取引先CD
        this.supplierList = this.$route.params.supplierList; // 取引先リスト
        this.officeList = this.$route.params.officeList; // 営業所リスト
        // 品質区分リスト
        this.qualityDivList = this.$route.params.qualityDivList;
        // ユーザーメーカー区分リスト
        this.userMakerList = this.$route.params.userMakerList;
        // 在庫有無区分リスト
        this.inStockList = this.$route.params.inStockList;
        // 品番リスト
        this.nameList = this.$route.params.nameList;
        if (this.nameSelected) {
          // 品番ヒント
          this.productHint = this.nameList.find((v) => v.value == this.nameSelected).text;
        }

        this.apigetStock();
      } else {
        // コードマスタ取得
        this.getInitData();
        // 取引先リスト取得
        this.apigetAllComSupplierList();
      }

      // 品番検索ラベル
      this.itemCdLabelList = this.getItemCdLabel();
      this.itemCdLabel = this.getProductCdLabel(this.userMakerSected);
      // 品番前方一致検索ラベル
      this.matchItemCdLabelList = this.getMatchItemCdLabel();
      this.matchItemCdLabel = this.getMatchUserProductCdLabel(this.userMakerSected);
      this.$watch(
        () => this.userMakerSected,
        (newVal) => {
          this.chengeUserMakerSected(newVal);
        }
      );
    },
    //ユーザーメーカー切り替え処理
    chengeUserMakerSected(newVal) {
      if (this.clearFlg) {
        this.userMakerSected = "02";
        this.clearFlg = false;
        return;
      }
      this.itemCdLabel = this.getProductCdLabel(newVal);
      this.matchItemCdLabel = this.getMatchUserProductCdLabel(newVal);
      // 画面遷移時は商品コードリストを空にしない
      if (!this.$route.params.searchParam) {
        this.nameList = [];
        this.nameSelected = "";
      }
    },
    /**
     * ユーザーメーカ区分と品番のマッピング取得
     */
    getItemCdLabel() {
      return [
        {
          userMakerSected: "",
          label: i18n.tc("label.lbl_productCnCd"),
        },
        {
          userMakerSected: "01",
          label: i18n.tc("label.lbl_userProductCd"),
        },
        {
          userMakerSected: "02",
          label: i18n.tc("label.lbl_productCnCd"),
        },
      ];
    },

    /**
     * ユーザーメーカ区分と品番前方一致のマッピング取得
     */
    getMatchItemCdLabel() {
      return [
        {
          userMakerSected: "",
          label: i18n.tc("label.lbl_leftHandMatchProduct"),
        },
        {
          userMakerSected: "01",
          label: i18n.tc("label.lbl_leftHandMatchUserProduct"),
        },
        {
          userMakerSected: "02",
          label: i18n.tc("label.lbl_leftHandMatchProduct"),
        },
      ];
    },
    // 初期データ取得
    getInitData() {
      const qualityList = getParameter.getCodeMst(appConfig.CODETYPE.IN_QUALITY_DIV);
      // ユーザー・メーカー区分
      const userMakerList = getParameter.getCodeMst(appConfig.CODETYPE.USER_MAKER_SELECT);
      const inStockDivList = getParameter.getCodeMst(appConfig.CODETYPE.IN_STOCK_DIV);
      Promise.all([qualityList, userMakerList, inStockDivList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.qualityDivList = result[0];
          this.userMakerList = result[1];
          this.inStockList = result[2].splice(0, 1);
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },
    /**
     * 品番/品名ラベル取得
     */
    getProductCdLabel(val) {
      return this.itemCdLabelList.find((v) => v.userMakerSected == val).label;
    },
    /**
     * 品番前方一致検索ラベル取得
     */
    getMatchUserProductCdLabel(val) {
      return this.matchItemCdLabelList.find((v) => v.userMakerSected == val).label;
    },
    /**
     * 取引先変更イベント
     */
    changeSuppliersSelected() {
      // 画面遷移後は営業所取得を行わない
      if (!this.$route.params.searchParam) {
        this.officeSelected = "";
        this.officeList = [];
        this.apigetSalesOfficeList();
      }
      this.apigetSalesOfficeList();
    },

    /**
     * ページング処理
     */
    showList() {
      return this.inputTableList;
    },

    // 取引先コードセット処理
    setSrhSupplier() {
      return commonFunction.getListSetName(this.supplierList, this.suppliersSelected);
    },
    getClientName() {
      return commonFunction.getListSetText(
        this.supplierList,
        this.inputList[this.editedIndex].clientSid
      );
    },
    // 商品コードセット処理
    checkProductCd() {
      return commonFunction.getListSetName(this.nameList, this.nameSelected);
    },
    /**
     * ユーザー品番コードリストボックス取得処理
     */
    setSrhUserProductCd() {
      for (var i = 0; i < this.userProductCdList.length; i++) {
        if (this.userProductCdList[i].value == this.userProductCd) {
          return this.userProductCdList[i].name;
        }
      }
    },

    // 在庫照会検索
    apigetStock() {
      // 入力チェック判定
      let isInputCheckResult = false;
      var now = new Date();
      this.today = dateTimeHelper.toStringDate("YYYY/MM/DD", now);
      this.nowTime = dateTimeHelper.toStringDate("hh:mm", now);
      this.alertSupplierMessage = "";
      // 取引先存在チェック
      if (this.isEmpty(this.suppliersSelected)) {
        this.alertSupplierMessage = i18n.tc("check.chk_inputSupplier");
        return;
      }
      // 現在時刻を表示
      if (this.$route.params.searchParam) {
        // 他画面からの遷移の場合、入力チェックは実施しない。
        isInputCheckResult = true;
      } else {
        // 上記外の場合はチェックを行う。
        isInputCheckResult = this.$refs.form.validate();
      }

      if (isInputCheckResult) {
        // 取引先コード取得
        this.supplierList.forEach((v) => {
          let sup = v.value;
          let select = this.suppliersSelected;
          sup.forEach((data) => {
            for (let j = 0; j < select.length; j++) {
              if (data == select[j]) {
                this.clientCd = v.clientCd;
              }
            }
          });
        });
        const config = this.$httpClient.createGetApiRequestConfig();

        // ローディング画面表示ON
        this.loadingCounter = 1;

        // 取引先
        if (this.suppliersSelected) {
          config.params.clientSid = this.suppliersSelected;
        }
        // 営業所SID
        if (this.officeSelected) {
          config.params.officeSid = this.officeSelected;
        }
        // 処理区分
        if (this.qualityDivSelected) {
          config.params.qualityDiv = this.qualityDivSelected;
        }

        // 品番/品名
        if (this.nameSelected) {
          // ユーザー・メーカー区分がメーカーの場合
          if (this.userMakerSected == "02") {
            // 商品標準SID
            config.params.itemStandardSid = this.nameSelected;
            // 品番
            config.params.itemCd = this.nameList.find((v) => v.value == this.nameSelected).code;
          } else {
            // ユーザー品番SID
            config.params.itemStandardSid = this.nameSelected;
            // ユーザー基本品番
            config.params.userItemBaseCd = this.nameList.find(
              (v) => v.value == this.nameSelected
            ).baseCd;
          }
        }

        // 品番前方一致検索キーワード
        if (this.itemSelected) {
          config.params.itemSelected = this.itemSelected;
        }

        // ユーザーメーカ区分
        if (this.nameSelected || this.itemSelected) {
          config.params.userMakerSected = this.userMakerSected;
        }

        // 在庫有無
        if (this.inStockSelected) {
          config.params.inStock = this.inStockSelected;
        }

        // 取引先コード
        if (this.clientCd) {
          config.params.clientCd = this.clientCd;
        }

        // 検索条件の保持
        this.searchParam.searchSuppliersSelected = this.suppliersSelected;
        this.searchParam.searchOfficeSelected = this.officeSelected;
        this.searchParam.searchProductCd = this.nameSelected;
        this.searchParam.searchUserProductCd = this.userProductCd;
        this.searchParam.searchQualityDiv = this.qualityDivSelected;
        this.searchParam.searchInstockDiv = this.inStockSelected;
        this.searchParam.searchItemSelected = this.itemSelected;
        this.searchParam.searchClientCd = this.clientCd;

        // ページング
        return new Promise((resolve, reject) => {
          this.$httpClient
            // 接続先のAPI_URLを入力
            .secureGet(appConfig.API_URL.BIZ_STOCK_BY_CLIENT, config)
            // .testAsynchronousApiAsync("GET", appConfig.API_URL.BIZ_STOCK_BY_CLIENT, config)
            .then((response) => {
              // console.debug("apigetStock() Response", response);
              const jsonData = JSON.parse(JSON.stringify(response.data));
              // 正常時
              if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
                const list = [];
                this.stockList = jsonData.resIdv.stocksByClient;
                for (let i = 0; i < this.stockList.length; i++) {
                  const officeSumList = [];
                  this.officeSumList = this.stockList[i].officeSumList;
                  this.officeSumList.forEach((v) =>
                    officeSumList.push({
                      officeSid: v.officeSid,
                      salesOffice: v.salesOffice,
                      quantity: commonUtil.formatToCurrency(Number(v.quantity)),
                    })
                  );

                  list.push({
                    itemCd: this.stockList[i].itemCd,
                    itemName: this.stockList[i].itemName,
                    accessoryCd: this.stockList[i].itemSubCd,
                    itemStandardSid: this.stockList[i].itemStandardSid,
                    arrivalStatus: this.stockList[i].qualityDiv,
                    total: commonUtil.formatToCurrency(Number(this.stockList[i].total)),
                    totalSum: commonUtil.formatToCurrency(Number(this.stockList[i].totalByQuality)),
                    officeSumList: officeSumList,
                  });
                }

                this.inputList = list;
                this.groupCountList = [...new Set(this.inputList.map((data) => data.itemCd))];
                this.inputTableList = this.inputList.map((item) => {
                  let groupByNo;
                  groupByNo = item.itemCd;
                  const inputItem = {
                    groupByNo: groupByNo,
                    ...item,
                  };
                  return inputItem;
                });
                this.totalCount = this.groupCountList.length;
                resolve(response);
              } else {
                this.infoDialog.message = jsonData.resCom.resComMessage;
                this.infoDialog.title = "結果";
                this.infoDialog.isOpen = true;
                this.infoDialog.screenFlag = true;
                reject(resolve);
              }
            })
            .catch((resolve) => {
              console.error("apigetStock() Resolve", resolve);
              this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              reject(resolve);
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        });
      }
    },

    // 在庫情報出力画面：データ出力ボタン押下時
    outPutClick() {
      // 取引先存在チェック
      if (this.isEmpty(this.suppliersSelected)) {
        this.alertSupplierMessage = i18n.tc("check.chk_inputSupplier");
        return;
      }
      if (this.$refs.form.validate()) {
        // 取引先コード取得
        this.supplierList.forEach((v) => {
          let sup = v.value;
          let select = this.suppliersSelected;
          sup.forEach((data) => {
            for (let j = 0; j < select.length; j++) {
              if (data == select[j]) {
                this.clientCd = v.clientCd;
              }
            }
          });
        });
        // ローディング画面表示ON
        this.loadingCounter = 1;

        const config = this.$httpClient.createRequestBodyConfig();

        config.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_CLT_001;

        // 取引先
        if (this.suppliersSelected) {
          config.reqIdv.clientSid = this.suppliersSelected;
        }
        // 営業所SID
        if (this.officeSelected) {
          config.reqIdv.officeSid = this.officeSelected;
        }
        // 処理区分
        if (this.qualityDivSelected) {
          config.reqIdv.qualityDiv = this.qualityDivSelected;
        }

        // 品番/品名
        if (this.nameSelected) {
          // ユーザー・メーカー区分がメーカーの場合
          if (this.userMakerSected == "02") {
            // 商品標準SID
            config.reqIdv.itemStandardSid = this.nameSelected;
            // 品番
            config.reqIdv.itemCd = this.nameList.find((v) => v.value == this.nameSelected).code;
          } else {
            // ユーザー品番SID
            config.reqIdv.itemStandardSid = this.nameSelected;
            // ユーザー基本品番
            config.reqIdv.userItemBaseCd = this.nameList.find(
              (v) => v.value == this.nameSelected
            ).baseCd;
          }
        }

        // 品番前方一致検索キーワード
        if (this.itemSelected) {
          config.reqIdv.itemSelected = this.itemSelected;
        }

        // ユーザーメーカ区分
        if (this.nameSelected) {
          config.reqIdv.userMakerSected = this.userMakerSected;
        }

        // 在庫有無
        if (this.inStockSelected) {
          config.reqIdv.inStock = this.inStockSelected;
        }

        // 取引先コード
        if (this.clientCd) {
          config.reqIdv.clientCd = this.clientCd;
        }

        return new Promise((resolve, reject) => {
          this.$httpClient
            // 接続先のAPI_URLを入力
            .securePost(appConfig.API_URL.STOCK_BY_CLIENT_SLIP_FORMAT, config, appConfig.APP_CONFIG)
            .then((response) => {
              // // console.debug("btnSearch() Response", response);
              const jsonData = JSON.parse(JSON.stringify(response.data));

              // 正常時
              if (jsonData.resCom.resComCode == "000") {
                // 返却されたurlを共通関数へ呼び出し
                commonFunction.getLink(jsonData.resIdv.filePath);
                resolve(response);
              } else {
                // エラー時(件数0件またはapiエラー時)
                this.infoDialog.message = jsonData.resCom.resComMessage;
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.firstPageFlag = true;
                reject();
              }
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              reject();
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        });
      }
    },
    /**
     * プルダウンチェンジイベント
     */
    changeProductNm(value) {
      // 既存品番/品名を選択時
      let val = this.nameList.find((v) => v.value == value);
      if ((val ?? false) && value != "") {
        this.productHint = val.text;
        this.search = val.text;
      }
      this.nameList = this.nameList.filter((v) => v.value == value);
    },

    // 項目が空かチェック
    isEmpty(value) {
      if (value === undefined || value === null || value === "" || value.length == 0) {
        return true;
      }
      return false;
    },

    // ユーザー品番プルダウンチェンジイベント
    changeUserProductCd(value) {
      // 既存のユーザー品番/品名を選択時
      let val = this.userProductCdList.find((v) => v.value == value);
      if ((val ?? false) && value != "") {
        this.userProductHint = val.text;
        this.searchUserProductCd = val.text;
      }
      this.userProductCdList = this.userProductCdList.filter((v) => v.value == value);

      // 値が設定されていない場合はクリアする
      if (value == "" || value == null) {
        this.userProductHint = "";
      } else {
        // 処理なし
      }
    },
    /**
     * 詳細画面を開く
     */
    toDetail(item) {
      //入力チェック
      if (this.$refs.form.validate()) {
        this.supplierList.forEach((v) => {
          let sup = v.value;
          let select = this.suppliersSelected;
          sup.forEach((data) => {
            for (let j = 0; j < select.length; j++) {
              if (data == select[j]) {
                this.suppliersSelectedName = v.text;
              }
            }
          });
        });
        this.$router.push({
          name: appConfig.SCREEN_ID.P_CLT_002,
          params: {
            stockInquiryList: {
              // 検索条件
              headerList: this.searchParam,
              // 取引先名
              suppliersSelectedName: this.suppliersSelectedName,
              // 取引先リスト
              supplierList: this.supplierList,
              // 営業所リスト
              officeList: this.officeList,
              // 品質区分リスト
              qualityDivList: this.qualityDivList,
              // ユーザーメーカー区分リスト
              userMakerList: this.userMakerList,
              // 在庫有無区分リスト
              inStockList: this.inStockList,
              // 品番リスト
              nameList: this.nameList,
              // 取引先
              suppliersSelected: this.suppliersSelected,
              // 選択行の情報
              item: item,
              // 在庫有無
              strageDiv: this.inStockSelected
                ? this.inStockList.find((v) => v.value === this.inStockSelected).text
                : this.inStockSelected,
              // 遷移元
              screenName: appConfig.SCREEN_ID.P_CLT_001,
            },
          },
        });
      } else {
        //遷移しない
        return;
      }
    },

    // 営業所取得
    apigetSalesOfficeList() {
      const config = this.$httpClient.createGetApiRequestConfig();

      // ローディング画面表示ON
      this.loadingCounter = 1;

      config.params.clientSid = this.suppliersSelected;
      config.params.reqComCompanySid = sessionStorage.getItem("comp_sid");
      config.params.reqComOfficeSid = sessionStorage.getItem("sales_office_sid");
      config.params.reqComDeviceImei = "WEB";
      config.params.reqComAppVersion = appConfig.APPVERSION;
      config.params.reqComExecUser = sessionStorage.getItem("usercode");
      config.params.reqComExecTimestamp = dateTimeHelper.convertUTC();
      config.params.reqComRequestId = uuidv4();
      config.params.reqComPageIndex = 1;
      config.params.reqComPageLimit = 100;
      config.params.reqComLanguageCd = sessionStorage.getItem("lang");
      config.params.reqComReferenceDate = dateTimeHelper.convertUTC();
      config.params.reqComTimeZone = dateTimeHelper.getTimeZone();
      // ページング
      config.params.reqComPaginationFlg = "0";
      console.log("apigetSalesOffice() Config", config);
      //APIに渡す値をconfig.paramsにセット==================================//

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(
            appConfig.API_URL.BIZ_OFFICELIST_TRANSITION_FOR_STOCK_INQUERY_BY_CLIENT,
            config
          )
          .then((response) => {
            console.log("apigetSalesOffice() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              const list = [];

              this.transition = jsonData.resIdv.officeListForStockInqueryByClient;

              for (let i = 0; i < this.transition.length; i++) {
                if (this.transition[i].relatedPartySid != null) {
                  list.push({
                    text: `${this.transition[i].relatedPartyName}`, // 営業所
                    value: this.transition[i].relatedPartySid,
                    name: this.transition[i].relatedPartyNameShort,
                  });
                }
              }
              this.officeList = list;
              resolve(response);
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              reject(resolve);
            }
          })
          .catch((resolve) => {
            console.error("apigetSalesOffice() Resolve", resolve);
            this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            reject(resolve);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    // 取引先取得
    apigetAllComSupplierList() {
      const config = this.$httpClient.createGetApiRequestConfig();

      // ローディング画面表示ON
      this.loadingCounter = 1;

      config.params.reqComCompanySid = sessionStorage.getItem("comp_sid");
      config.params.reqComOfficeSid = sessionStorage.getItem("sales_office_sid");
      config.params.reqComDeviceImei = "WEB";
      config.params.reqComAppVersion = appConfig.APPVERSION;
      config.params.reqComExecUser = sessionStorage.getItem("usercode");
      config.params.reqComExecTimestamp = dateTimeHelper.convertUTC();
      config.params.reqComRequestId = uuidv4();
      config.params.reqComPageIndex = 1;
      config.params.reqComPageLimit = 100;
      config.params.reqComLanguageCd = sessionStorage.getItem("lang");
      config.params.reqComReferenceDate = dateTimeHelper.convertUTC();
      config.params.reqComTimeZone = dateTimeHelper.getTimeZone();

      // ページング
      config.params.reqComPaginationFlg = "0";
      console.log("apigetAllComSupplier() Config", config);
      //APIに渡す値をconfig.paramsにセット==================================//

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(
            appConfig.API_URL.BIZ_CLIENTLIST_TRANSITION_FOR_STOCK_INQUERY_BY_CLIENT,
            config
          )
          .then((response) => {
            console.log("apigetAllComSupplier() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              const list = [];

              this.transition = jsonData.resIdv.clientListForStockInqueryByClient;

              for (let i = 0; i < this.transition.length; i++) {
                list.push({
                  text: `${this.transition[i].compNameShort}`, // 営業所
                  value: this.transition[i].clientSidList,
                  name: this.transition[i].compNameShort,
                  officeVal: this.transition[i].officeSidList,
                  clientCd: this.transition[i].clientCd,
                });
              }
              this.supplierList = list;
              resolve(response);
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              reject(resolve);
            }
          })
          .catch((resolve) => {
            console.error("apigetAllComSupplier() Resolve", resolve);
            this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            reject(resolve);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
    /**
     * 画面クリアボタン押下時の処理
     */
    clearScreen() {
      this.confirmDialog.message = messsageUtil.getMessage(appConfig.CONSTANT.CLEAR_MESSAGE, [
        i18n.tc("btn.btn_clear"),
      ]);
      this.confirmDialog.isOpen = true;
      this.confirmDialog.screenFlag = true;
      this.confirmDialog.changeFlag = false;
      this.confirmDialog.okAction = this.clearScreenOkClick;
    },
    /**
     * 画面クリア
     */
    clearScreenOkClick() {
      // 初期表示の設定
      Object.assign(this.$data, this.defaultData);
      // クリアフラグ
      this.clearFlg = true;
      this.userMakerSected = "02";
      // 初期化
      this.init();
      // バリデーションチェックをリセット
      this.$refs.form.resetValidation();
    },
  },
  computed: {},
  watch: {
    //取引先変更時
    suppliersSelected() {
      if (!this.$route.params.searchParam) {
        //品番/品名リストクリア
        this.nameSelected = "";
        this.nameList = [];
      }
      this.alertSupplierMessage = "";
    },
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
    dialog(val) {
      val || this.close();
    },
    page: function (newValue) {
      //画面クリア時の場合
      if (this.clearFlg) {
        return;
      }
      this.page = newValue;
    },

    search(val) {
      if (val ?? false) {
        this.officeSid = [];
        // 取引先が選択されてない場合、処理しない
        if (this.suppliersSelected == null || this.suppliersSelected == "") {
          return;
        }
        // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
        if (val == this.productHint) {
          return;
        }
        this.supplierList.forEach((v) => {
          let sup = v.value;
          let select = this.suppliersSelected;
          sup.forEach((data) => {
            for (let i = 0; i < select.length; i++) {
              if (data == select[i]) {
                this.officeSid.push(v.officeVal[i]);
              }
            }
          });
        });
        // this.officeSid = this.officeSid.distinct;
        // 入力桁数が定義した数異常の場合検索処理
        if (val.length >= appConfig.CNTCHARITEM) {
          const itemsBiz =
            this.userMakerSected == "02"
              ? getParameter.getItemsBizAddName2ForStockInquery(
                  this.suppliersSelected,
                  dateTimeHelper.convertUTC(),
                  val,
                  this.officeSid
                )
              : getParameter.getUserItemsBizAddNameForStockByClient(true, val);

          Promise.all([itemsBiz])
            .then((result) => {
              // 画面の初期値を設定します。
              const list = [];
              const nameList = list.concat(result[0]);
              this.nameList = Array.from(
                new Map(nameList.map((item) => [item.code, item])).values()
              );
              this.nameList.unshift({ name: "", text: "", value: "", code: "" });
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        } else {
          this.nameList.length = 0;
        }
      }
    },

    // ユーザー品番取得処理
    searchUserProduct(val) {
      if (val ?? false) {
        // ユーザー品番が選択されてない場合、処理しない
        if (this.suppliersSelected == null || this.suppliersSelected == "") {
          return;
        }
        // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
        if (val == this.userProductHint) {
          return;
        }
        // スペース入力のみの場合、API側で全件検索となり画面が重くなるためAPIの実行をキャンセル
        if (val.trim().length === 0) {
          return;
        }
        // 入力桁数が定義した数以上の場合検索処理
        if (val.length >= appConfig.CNTCHARITEM) {
          // 選択した会社コードを取得

          const fromList = getParameter.getUserItemsBizAddName(this.suppliersSelected, val);
          Promise.all([fromList])
            .then((result) => {
              // 画面の初期値を設定します。

              this.userProductCdList = result[0];
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        } else {
          this.userProductCdList.length = 0;
          this.userProductCdList = [];
        }
      } else {
        this.userProductCdList.length = 0;
        this.userProductCdList = [];
        this.userProductCd = "";
      }
    },
  },

  mounted() {
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
}

.text-overflow {
  // v-tooltip
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.toatlNum {
  margin-left: 10px;
}

.nowTime {
  font-weight: bold;
  font-size: large;
}

.strageQty {
  font-size: medium;
}

.border {
  border-bottom: 1px solid black;
  padding: 10px;
}
.font-size ::v-deep {
  font-size: 26px;
  text-align: right;
}
.font-size-char ::v-deep {
  font-size: 26px;
  text-align: left;
  padding-left: 10px;
}
.font-size-b {
  font-weight: bold;
  font-size: 26px;
}
.border_top {
  border-top: 1px solid gray;
  height: 40px;
}
.border_top_num {
  border-top: 1px solid gray;
  font-size: 26px;
  text-align: right;
}
.border_top_char {
  border-top: 1px solid gray;
  font-size: 26px;
  text-align: left;
  padding-left: 10px;
}

.border_top_saleoffice {
  border-top: 1px solid gray;
  padding-left: 40px;
  margin: 0px, 0px, 8px;
  height: 40px;
}

.not_border_top_saleoffice {
  padding-left: 40px;
  margin: 0px, 0px, 8px;
  height: 39px;
}

.border_top_officeOne {
  border-top: 1px solid gray;
  padding: 8px 0px 140px 0px;
}

.border_top_quality {
  border-top: 1px solid gray;
  padding: 0px 0px 90px 10px;
  font-size: 26px;
}

.border_top_sum {
  border-top: 1px solid gray;
  padding: 0px 0px 90px 0px;
  font-size: 26px;
}

.not-border {
  padding: 13px;
}
.not-border-one {
  padding: 0px 13px 13px 13px;
}
.padd-dtl-btn {
  padding-bottom: 180%;
}

.padd-item {
  padding-bottom: 80%;
}

.padd-itemSub {
  padding-bottom: 90%;
}

.padd-item-one {
  padding-bottom: 0px;
}

.padd-itemSub-one {
  padding-bottom: 95px;
}

.padd-total {
  padding-bottom: 235px;
  font-size: 26px;
}

.padd-total-one {
  padding-bottom: 140px;
  font-size: 26px;
}

.padd-item-oneOffice {
  padding-bottom: 260px;
}

.padd-total-oneOffice {
  padding-bottom: 315px;
  font-size: 26px;
}

.not-border-quality {
  padding-bottom: 105px;
  padding-left: 10px;
  font-size: 26px;
}

.not-border-quality-oneOffice {
  padding-bottom: 145px;
  padding-left: 10px;
  font-size: 26px;
}

.border_top_quality_oneOffice {
  border-top: 1px solid gray;
  padding-bottom: 130px;
  padding-left: 10px;
  font-size: 26px;
}

.not-border-quality-one {
  padding-bottom: 140px;
  padding-left: 10px;
  font-size: 26px;
}

.not-border-sum {
  padding-bottom: 105px;
  font-size: 26px;
}

.not-border-sum-one {
  padding-bottom: 140px;
  padding-left: 10px;
  font-size: 26px;
}

.not-border-one-office {
  padding-bottom: 110px;
  padding-left: 10px;
  font-size: 26px;
}

.not-border-ofiiceOne {
  padding-bottom: 155px;
  padding-top: 0px;
}

.not-border-office-padd {
  padding: 10px;
}

.total-sum-header {
  padding-right: 0px;
}

#listData.v-data-table ::v-deep th.total-sum-header {
  padding-right: 0px;
  padding-left: 30px;
  margin-right: 10px;
  text-align: right;
}

#listData.v-data-table ::v-deep th.office-header {
  padding-left: 5px;
}

#listData.v-data-table ::v-deep th.qty-header {
  padding-right: 0px;
}

::v-deep .v-dialog #detailsDialig .v-input__slot {
  width: 150%;
}

//選択項目枠変更用
// .search-autocomplete-supplierList {
//   width: 21rem;
// }
</style>
